import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { Injectable, Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './navmenu/navmenu.component';
import { SearchComponent } from './search/search.component';
import { IssueComponent } from './issue/issue.component';
import { ReadComponent } from './read/read.component';
import { AboutComponent } from './about/about.component';
import { LinksComponent } from './links/links.component';
import { AdvertiseComponent } from './advertise/advertise.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { DexportalComponent } from './dexportal/dexportal.component';
import { SearchService } from './Services/search.service';
import { CustnavComponent } from './custnav/custnav.component';
import { ViewComponent } from './view/view.component';
import { TopComponent } from './top/top.component';
import { FwrdComponent } from './fwrd/fwrd.component';
import { UsComponent } from './us/us.component';


const appRoutes: Routes = [
  { path: 'issue', component: IssueComponent },
  { path: '', redirectTo: '/issue', pathMatch: 'full' },
  { path: 'search', component: SearchComponent },
  { path: 'issue', component: IssueComponent },
  { path: 'read/:issueid', component: ReadComponent },
  { path: 'read/:issueid/:page', component: ReadComponent },
  { path: 'fwrd/issueid/page', component: FwrdComponent },
  { path: 'r/:issueid/:page', component: ReadComponent },
  { path: 'r/:issueid', component: ReadComponent },
  { path: 'view/:itemid', component: ViewComponent },
  { path: 'about', component: AboutComponent },
  { path: 'links', component: LinksComponent },
  { path: 'advertise', component: AdvertiseComponent },
  { path: 'guidelines', component: GuidelinesComponent },
  { path: 'top', component: TopComponent },
  { path: 'us', component: UsComponent },
  { path: '**', redirectTo: '/issue' }
]

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    IssueComponent,
    SearchComponent,
    ReadComponent,
    AboutComponent,
    LinksComponent,
    AdvertiseComponent,
    GuidelinesComponent,
    DexportalComponent,
    CustnavComponent,
    ViewComponent,
    TopComponent,
    FwrdComponent,
    UsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [SearchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
