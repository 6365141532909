import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { search } from '../Models/search';
import { searchResult } from '../Models/searchResult';
import { issue } from '../Models/issue';
import { person } from '../Models/person';
import { Observable } from "rxjs/Observable";
import "rxjs/Rx";
import { VisionResponse } from '../Models/visionResponse';

@Injectable()
export class SearchService {
  //for holding current issue
  readIssue = new issue();

  constructor(private http: HttpClient) { }
  //https://localhost:44368/dex/all/
  getResults(s: string): Observable<searchResult[]> {
    return this.http.get<searchResult[]>('https://api.rangedex.com/all/' + s)
  }
  getItem(wnt: string): Observable<searchResult> {
    return this.http.get<searchResult>('https://api.rangedex.com/getitem/' + wnt)
  }
  getIssues(): Observable<issue[]> {
    return this.http.get<issue[]>('https://api.rangedex.com/issues/')
  }
  getIssue(id:string): Observable<issue> {
    return this.http.get<issue>('https://api.rangedex.com/issue/'+id)
  }
  getTop(): Observable<searchResult[]> {
    return this.http.get<searchResult[]>('https://api.rangedex.com/top')
  }

  topAuthors(): Observable<person[]> {
    return this.http.get<person[]>('https://api.rangedex.com/topauthors')
  }

  topGraphers(): Observable<person[]> {
    return this.http.get<person[]>('https://api.rangedex.com/topgraphers')
  }

  getAuthor(s: string): Observable<searchResult[]> {
    return this.http.get<searchResult[]>('https://api.rangedex.com/author/'+s)
  }

  getPhotographer(s: string): Observable<searchResult[]> {
    return this.http.get<searchResult[]>('https://api.rangedex.com/photographer/' + s)
  }

  getOcrPage(s: string): Observable<VisionResponse> {
    return this.http.get<VisionResponse>('https://api.rangedex.com/ocrpage/' + s)
  }

  putReadInfo(info: issue) {
    this.readIssue = info;
  }

  getReadInfo(): issue {
    return this.readIssue;
  }

}

