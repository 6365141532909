import { Component, Inject } from '@angular/core';
import { Http } from '@angular/http';

@Component({
    selector: 'advertise',
    templateUrl: './advertise.component.html'
})
export class AdvertiseComponent {

}

