import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custnav',
  templateUrl: './custnav.component.html',
  styleUrls: ['./custnav.component.css']
})
export class CustnavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
