import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { issue } from '../Models/issue';
import { SearchService } from '../Services/search.service';
import { RouterModule } from '@angular/router';
import { single } from 'rxjs/operator/single';
import { VisionResponse } from '../Models/visionResponse';

@Component({
  selector: 'read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.css'],
  providers: [SearchService]
})
export class ReadComponent implements OnInit {
  @Input() issueid: string;
  @Input() page: string;
  @Input() zoom: number;
  chosenIssue: issue;
  vResponse: VisionResponse;
  vResponseL: VisionResponse;
  vResponseR: VisionResponse;
  //dictionary with pages
  //vResponses: [{key:string, val:VisionResponse}] ;
  //for sizing pages, etc
  forceSinglePage = false;
  singlePage = false;
  onMaxPage = false;
  sliderx = 14;
  width = 500;
  height = 650
  pageLeft = 0;
  pageRight = 1;
  singlePgNum = 1;
  left = false;
  maxPg = 99;
  //for passing
  pageValue = 1;
  rOutput="Hi.";
  ShowOCR = true;
  OCRCount = 0;

    async getOcrPage(s: string) {
    //make api call
    await this.searchService.getOcrPage(s)
      .subscribe(OCRdata => { this.vResponse = OCRdata });
    //add new response to dictionary with key=pageid, value=response.
    this.ShowOCR = true;
  }

  async OCRl() {
    var s = this.issueid + '-' + this.pageLeft.toString();
    await this.searchService.getOcrPage(s)
      .subscribe(OCRdata => { this.vResponseL = OCRdata });
  }
  async OCRr() {
    var s = this.issueid + '-' + this.pageRight.toString();
    await this.searchService.getOcrPage(s)
      .subscribe(OCRdata => { this.vResponseR = OCRdata });
  }

  
  //updates pages for viewing.
  updatePage() {
    //if even
if (this.pageValue % 2==0) {
      this.pageLeft = this.pageValue;
      this.pageRight = this.pageValue + 1;
    }
    //if odd
    else if (this.pageValue % 2 != 0) {
      this.pageLeft = this.pageValue-1;
      this.pageRight = this.pageValue;
    }
    this.singlePgNum = this.pageValue;
    //call ocr
    this.OCRl();
    this.OCRr();
  }

  pageUp(): void {
    if (this.pageValue < this.chosenIssue.maxpage) {
      if (this.pageValue == this.chosenIssue.maxpage - 1 || this.singlePage) {
        this.pageValue += 1;
      }
      else {
        this.pageValue += 2;
      }
      this.updatePage();
    }   
  }

  pageDown(): void {
    if (this.pageValue >= 2) {
      //if it is not exactly 2
      if (this.pageValue != 2) {
        //if full spread
        if (!this.singlePage) {
          this.pageValue -= 2;
        }
        //if single page
        else {
          this.pageValue -= 1;
        }
        
      }
      else //if it is exactly 2
      {
        this.pageValue -= 1;
      }
      this.updatePage();
    }
  }
  forgetIssue(): void {
    this.pageLeft = 1;
    this.pageRight = 2;
  };
  //gets params passed
  async getIssue() {
    const id = this.route.snapshot.paramMap.get('issueid');
    const pg = this.route.snapshot.paramMap.get('page');
    if (id) {
      this.issueid = id.toUpperCase();
      this.searchService.getIssue(this.issueid)
        .subscribe(data => { this.chosenIssue = data });     
    }
    if (pg) {
      this.page = pg;
      //+ casts number as string
      this.pageLeft = +this.page;
      this.pageRight = this.pageLeft;
      this.pageRight += 1;
      this.pageValue = this.pageLeft;
      this.singlePage = false;
      this.updatePage();
    }
    this.searchService.putReadInfo(this.chosenIssue);
  };


  sizeUp(): void {
    this.sliderx += 1;
    this.reSize();
  };
  sizeDown(): void {
    this.sliderx -= 1;
    this.reSize();
  };
  reSize(): void {
    this.height = 130 * (this.zoom / 2)
    this.width = 100 * (this.zoom / 2)
  };
  onePage(): void {
    this.forceSinglePage = true;
    this.singlePage = true;
  };
  twoPage(): void {
    this.pageValue = this.singlePgNum;
    this.forceSinglePage = false;
    this.singlePage = false;
  };
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private searchService: SearchService) { }
  ngOnInit(): void {
    this.getIssue();
    this.reSize();
    this.updatePage();
  }
}

interface read {
  id: string;
  photoid: string;
  readid: string;
  year: string;
  season: string;
  contents: string;
  notes: string;
}

