import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dexportal',
  templateUrl: './dexportal.component.html',
  styleUrls: ['./dexportal.component.css']
})
export class DexportalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
