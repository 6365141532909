import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'fwrd',
  templateUrl: './fwrd.component.html',
  styleUrls: ['./fwrd.component.css']
})
export class FwrdComponent implements OnInit {
  @Input() issueid: string;
  @Input() page: string;


  constructor(private l:Location, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('issueid');
    const pg = this.route.snapshot.paramMap.get('page');
    //this.l.path = '/read/';
  }

}
