import { Component, Inject } from '@angular/core';
import { Http } from '@angular/http';

@Component({
    selector: 'about',
    templateUrl: './about.component.html'
})
export class AboutComponent {

}

