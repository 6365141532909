import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { search } from '../Models/search';
import { searchResult } from '../Models/searchResult';
import { issue } from '../Models/issue';
import { SearchService } from '../Services/search.service';
import "rxjs/Rx";
import { RouterModule, Router } from '@angular/router';

import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [SearchService],
})
export class SearchComponent {
  public articles: searchResult[];
  searchstring = 'hello';
  forward = false;
  fwrdStr = 'read'
  showResults = false;
  model = new search('', '');
  btnToggle = document.getElementById('btnToggle');
  toggletxt = 'Hide Search';
  currentRead = new issue();
  readString = '';
  sortIssueAsc = true;
  searched = '';
  constructor(private searchService: SearchService, router:Router) { }

  searchcount = 0;

  searchBox = document.getElementById('searchBox')

  updateToggle(v?: boolean) {
    if (v==null) {
      if (this.showResults) {
        this.showResults = false;
        this.toggletxt = "Show"
      }
      else if (!this.showResults) {
        this.showResults = true;
        this.toggletxt = "Hide";
      }
    }
    else {    
    this.showResults = v;
    if (v) {
      this.toggletxt = "Hide";
    }
    else {
      this.toggletxt = "Show"
      }
    }
  }

  updateSearchString(s: string) {
    this.searchBox.textContent == s;
    this.model.phrase = s;
    this.doSearch();
}

  fwrd() {
    if (this.forward) {
      this.fwrdStr='read'
      this.forward = false;
    }
    else if (!this.forward) {
      this.fwrdStr='r'
      this.forward = true;
    }
    this.updateToggle(false)
  }

  doSearch() {
    this.searchService.getResults(this.model.phrase)
      .subscribe(data => { this.articles = data });
    this.searched = this.model.phrase;
    this.updateToggle(true);
    this.searchcount +=1;
  }

  hide() {
    this.updateToggle(false);
  }

  show() {
    this.updateToggle(true);
  }

  //searchType() {
  //  const searchBox = document.getElementById('searchBox');

  //  const example = fromEvent(searchBox, 'keyup').pipe(map(i => i.currentTarget.value));

  //  //wait to emit current value
  //  const debouncedInput = example.pipe(debounceTime(500));

  //  //log values
  //  const subscribe = debouncedInput.subscribe(val => {
  //    console.log(`Debounced Input: ${val}`);
  //  });
  //}

  //@HostListener('window:keyup', ['$event'])
  //onKeyup(event) {
  //  this.doSearch();
  //}
}

interface Article {
  id: string;
  Year: string;
  Issue: string;
  Title: string;
  Description: string;
  Page: string;
}
