import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
    selector: 'nav-menu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.css']
})

export class NavMenuComponent {
    //@Input() issueid: string;
    //constructor() { }
    //ngOnInit() {}
}
