export class issue {
  public issueid: string;
  public year: string;
  public season: string;
  public description: string;
  public path: string;
  public enabled: boolean;
  public maxpage: number;

  constructor()
  {
    
  }
}
