import { Component, OnInit, Inject, Input } from '@angular/core';
import { searchResult } from '../Models/searchResult';
import { SearchService } from '../Services/search.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css'],
  providers: [SearchService]
})
export class ViewComponent implements OnInit {

  @Input() itemid: string; 
  item: searchResult;
  cleanurl: any;
  urltxt: string;
  constructor(private ss: SearchService, private route: ActivatedRoute, private sanitizer: DomSanitizer ) { }

  getIssueID(): void {
    //need for getting values where they need to be
    const id = this.route.snapshot.paramMap.get('itemid');
    this.itemid = id;
  };  

  getItem()
  {
    if (this.itemid)
    { 
    this.ss.getItem(this.itemid)
      .subscribe(data => { this.item = data })
    }
  }

  ngOnInit()
  {
    this.getIssueID();
    this.getItem();
    this.cleanurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.item.url);
  }

}
