import { Component, OnInit } from '@angular/core';
import { SearchService } from '../Services/search.service';
import { searchResult } from '../Models/searchResult';
import { person } from '../Models/person';
import "rxjs/Rx";
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css'],
  providers: [SearchService]
})
export class TopComponent implements OnInit {
  public topAuthors: person[];
  public topGraphers: person[];
  public articles: searchResult[];
  public topArticles: searchResult[];
  public chosenOne = '';
  public detail = 'Articles';

  constructor(private searchService: SearchService, router:Router) { }

  getTopAuthors() {
    this.searchService.topAuthors()
      .subscribe(data => { this.topAuthors = data });
  }
  getTopGraphers() {
    this.searchService.topGraphers()
      .subscribe(data => { this.topGraphers = data });
  }

  doSearch(s: string) {
    this.chosenOne = s;
    this.searchService.getResults(this.chosenOne)
      .subscribe(data => { this.articles = data });
    this.detail='Articles'
  }

  getAuthor(s: string) {
    this.articles = [];
    this.chosenOne = s;
    this.searchService.getAuthor(this.chosenOne)
      .subscribe(data => { this.articles = data });
    this.detail='Articles'
  }

  getPhotographer(s: string) {
    this.articles = [];
    this.chosenOne = s;
    this.searchService.getPhotographer(this.chosenOne)
      .subscribe(data => { this.articles = data });
    this.detail = 'Photos'
  }

  ngOnInit() {
    this.searchService.topGraphers()
      .subscribe(data => { this.topGraphers = data });
    this.searchService.topAuthors()
      .subscribe(data => { this.topAuthors = data });
    this.searchService.getTop()
      .subscribe(data => { this.topArticles = data });
  }

}
