import { Component, Inject } from '@angular/core';
import { Http } from '@angular/http';

@Component({
    selector: 'guidelines',
    templateUrl: './guidelines.component.html'
})
export class GuidelinesComponent {
    title = "RANGE EDITORIAL GUIDELINES";
}

