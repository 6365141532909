import { Component, Inject, OnInit, Input } from '@angular/core';
import { SearchService } from '../Services/search.service';
import { issue } from '../Models/issue';
import "rxjs/Rx";

@Component({
    selector: 'issue',
    templateUrl: './issue.component.html',
    styleUrls: ['./issue.component.css'],
    providers: [SearchService],
})
export class IssueComponent implements OnInit{
  @Input() zoom: number;

  public issues: issue[];
  seasons: ["Spring","Summer","Fall","Winter"]
  public i: issue;
  x = 0;
  y = 1;
  ct = 0;
  issuecount = 0;
    //default size
    sliderx = 3;
    sizes = 2;
    width = (this.sliderx / 2) * 100;
    height = (this.sliderx / 2) * 130;
    pageLeft = 1;
    pageRight = 2;
    selectedIssue = "";
    issueid = "";

    onSelect(id: string): void { this.selectedIssue = id };   
    pageUp(requested: string): void { 
        this.pageLeft += 1;
        this.pageRight += 1;
    }
    pageDown(requested: string): void {

        this.pageLeft -= 1;
        this.pageRight -= 1;
    }
  //updates number of entries per row based on size.
    updateCSS(): void {
      if (this.sliderx <= 3) {
        this.sizes = 1;
      }
      if (this.sliderx > 3 && this.sliderx <= 6) {
        this.sizes = 2;
      }
      if (this.sliderx > 6 && this.sliderx <= 9) {
        this.sizes = 3;
      }
      if (this.sliderx > 9 && this.sliderx <= 12) {
        this.sizes = 4;
      }
      if (this.sliderx > 12) {
        this.sizes = 6;
      }
    }
    forgetIssue(): void {
    this.selectedIssue = "";
    this.pageLeft = 1;
    this.pageRight = 2;
    };
  constructor(private searchService: SearchService) { }

  //gets issues from api.
  getIssues() {
    this.searchService.getIssues()
      .subscribe(data => { this.issues = data });
  }

  ngOnInit() {
    this.getIssues();
  }
}

