import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'RANGE';
  windowInnerWidth = 0;
  windowInnerHeight = 0;
  searchHeight = 0;
  ngOnInit() {
    this.windowInnerWidth = window.innerWidth;
    this.windowInnerHeight = window.innerHeight;
  }
  adjust() {
    this.searchHeight = (this.windowInnerHeight / 3);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowInnerWidth = window.innerWidth;
    this.windowInnerHeight = window.innerHeight;
    this.adjust();
  }
}


